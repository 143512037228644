// Invoice statuses

.inv-status-overdue {
    background: #FED7D7;
    font-size: 13px;
    color: #9B2C2C;
    padding: 5px 10px;
}

.inv-status-paid {
    background: #D5EED0;
    font-size: 13px;
    color: #354930;
    padding: 5px 10px;
}

.inv-status-unpaid {
    background: #F8EDCB;
    font-size: 13px;
    color: #6C432E;
    padding: 5px 10px;
}

.inv-status-sent {
    background: rgba(246, 208, 154, 0.4);
    font-size: 13px;
    color: #A96E1A;
    padding: 5px 10px;
}

.inv-status-viewed {
    background: #C9E3EC;
    font-size: 13px;
    color: #1E576C;
    padding: 5px 10px;
}

.inv-status-completed {
    background: #D5EED0;
    font-size: 13px;
    color: #354930;
    padding: 5px 10px;
}

.inv-status-pending {
    background: #FED7D7;
    font-size: 13px;
    color: #9B2C2C;
    padding: 5px 10px;
}

.inv-status-partially_paid {
    background: #C9E3EC;
    font-size: 13px;
    color: #1E576C;
    padding: 5px 10px;
}

.inv-status-draft {
    background: #F8EDCB;
    font-size: 13px;
    color: #6C432E;
    padding: 5px 10px;
}


// Estimate Statuses

.est-status-expired {
    background: #FED7D7;
    font-size: 13px;
    color: #9B2C2C;
    padding: 5px 10px;
}

.est-status-accepted {
    background: #D5EED0;
    font-size: 13px;
    color: #354930;
    padding: 5px 10px;
}

.est-status-sent {
    background: rgba(246, 208, 154, 0.4);
    font-size: 13px;
    color: #A96E1A;
    padding: 5px 10px;
}

.est-status-viewed {
    background: #C9E3EC;
    font-size: 13px;
    color: #1E576C;
    padding: 5px 10px;
}

.est-status-pending {
    background: #FED7D7;
    font-size: 13px;
    color: #9B2C2C;
    padding: 5px 10px;
}

.est-status-rejected {
    background: #E1E0EA;
    font-size: 13px;
    color: #312F57;
    padding: 5px 10px;
}

.est-status-draft {
    background: #F8EDCB;
    font-size: 13px;
    color: #6C432E;
    padding: 5px 10px;
}
