// Font Awesome
// -------------------------

$fa-css-prefix: icon-fa !default;

// Colors
// -------------------------

$crater-theme:              #5851D8;
$crater-theme--light:       #817AE3;
$crater-theme--very-light:  rgba(88, 81, 216, 0.2);
$gray--very-light:          #F9FBFF;
$gray--light:               #EBF1FA;
$gray:                      #B9C1D1;
$gray--dark:                #A5ACC1;
$gray--very-dark:           #595959;
$red--dark:                 #9B2C2C;
$red--very-light:           #FED7D7;


$ls-color-black:            #040405;
$ls-color-black--light:     #263B5E;
$ls-color-blue:             #15B2EC;
$ls-color-green:            #00C99C;
$ls-color-red:              #FB7178;
$ls-color-secondary:        #55547A;
$ls-color-gray--very-light: $gray--very-light;
$ls-color-gray--light:      $gray--light;
$ls-color-gray:             $gray;
$ls-color-gray--dark:       $gray--dark;
$ls-color-gray--very-dark:  $gray--very-dark;
$ls-color-light-blue:       #15B2EC;
$ls-color-orange:           #F3AF4E;

$ls-color-red--dark:         $red--dark;
$ls-color-red--very-light:   $red--very-light;

$color-warning-light:           #6C432E;
$warning-light:                 #F8EDCB;
$color-success-light:           #354930;
$success-light:                 #D5EED0;

// Theme Colors
// -------------------------

$ls-color-primary:              $crater-theme;
$ls-color-primary--light:       $crater-theme--light;
$ls-color-primary--very-light:  $crater-theme--very-light;


// Gradients
// -------------------------

/*
    Commented by Abhishek Sharma on 14th January 2019
*/
/*


$primary-to-primary-light-gradient: linear-gradient(to right, $ls-color-primary, $ls-color-primary--light);
$primary-light-to-primary-gradient: linear-gradient(to right, $ls-color-primary--light, $ls-color-primary);
*/
/*
    End of introduction by Abhishek Sharma
*/

/*
    Introduced by Abhishek Sharma on 14th January 2019
*/
$primary-to-primary-light-gradient: linear-gradient(-45deg, #1045db 0%, #1045db 0%, #15095e 60%, #15095e 99%, #15095e 100%);
$primary-light-to-primary-gradient: linear-gradient(-45deg, #1045db 0%, #1045db 0%, #15095e 60%, #15095e 99%, #15095e 100%);

/*
    End of introduction by Abhishek Sharma
*/

// Shadows
// -------------------------

$shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
$shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
$shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
$shadow-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
$shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
$shadow-outline: 0 0 0 3px rgba(66, 153, 225, 0.5);

// Bootstrap Overrides
// -------------------------

$white: #fff;
$brand-primary:             $crater-theme;
$brand-secondary:           $ls-color-black;
$brand-success:             $ls-color-green;
$brand-info:                $ls-color-light-blue;
$brand-warning:             $ls-color-orange;
$brand-danger:              $ls-color-red;
$brand-dark:                $ls-color-secondary;

$btn-secondary-color:       $white;
$btn-secondary-bg:          $ls-color-black;
$btn-secondary-border:      $ls-color-black;

$theme-colors: (
    primary:  $ls-color-primary,
    secondary: $ls-color-secondary,
    success: $ls-color-green,
    info: $ls-color-light-blue,
    warning: $ls-color-orange,
    danger: $ls-color-red,
    light: $ls-color-primary--light,
    dark: $ls-color-secondary
);

$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{str-replace(str-replace(#{$navbar-dark-color}, "(", "%28"), ")", "%29")}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{str-replace(str-replace(#{$navbar-light-color}, "(", "%28"), ")", "%29")}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");


// -- HEADER SECTION --

$header-bg-color:                       $ls-color-primary;
$header-font-color:                     $white;
$header-buttons-bg-color:               $ls-color-black;
$header-buttons-bg-color--hover:        lighten($ls-color-black,5%);
$header-buttons-font-color:             $white;


// -- SIDEBAR SECTION --
$sidebar-bg-color:                      $ls-color-secondary;
$sidebar-font-color:                    $white;
$sidebar-icon-color:                    #9EA9C4;
$sidemenu-item-bg-color--hover:         lighten($ls-color-black,10%);
$sidemenu-item-bg-color--active:        transparent;
$sidemenu-item-font-color:              $white;
$sidemenu-item-font-color--active:      $ls-color-primary;
$sidemenu-arrow-color:                  $ls-color-gray--very-dark;
$sidemenu-submenu-bg:                   lighten($ls-color-black,3%);


// -- DROPDOWN --
$dropdown-bg:                           $ls-color-black;
$dropdown-link-color :                  $white;
$dropdown-border-color :                $ls-color-black;
$dropdown-divider-bg :                  $ls-color-gray--very-dark;
$dropdown-header-color :                $ls-color-primary;
$dropdown-header-border-color :         $ls-color-gray--very-dark;

// -- HORIZONTAL MENU --

$header-bottom-bg :                     $ls-color-black;
$header-menu-item-font-color :          darken($white,10%);
$header-menu-item-font-color--active :  $white;
$header-menu-item-bg-color--hover :     lighten($ls-color-black,10%);
$header-menu-item-bg-color--active :    lighten($ls-color-black,10%);

// -- MISC --
$code-bg : $ls-color-black;
$code-color : $ls-color-primary;


// Fonts
//----------------------------------

$base-font-family :             'Poppins', sans-serif;
$base-font-color :              lighten($ls-color-black, 15%);
$heading-font-color :           $ls-color-black;
$font-family-base :             $base-font-family;

$font-size-base:                1rem;
$font-size-section-title :      1.25rem;
$font-size-semi-section-title : 1.1rem;
$font-size-page-title :         1.5rem;



// Breakpoints
//----------------------------------

$x-small-breakpoint       :  480px;
$x-small-breakpoint-below :  $x-small-breakpoint - 1px;
$small-breakpoint         :  768px;
$small-breakpoint-below   :  $small-breakpoint - 1px;
$medium-breakpoint        :  992px;
$medium-breakpoint-below  :  $medium-breakpoint - 1px;
$large-breakpoint         :  1240px;
$large-breakpoint-below   :  $large-breakpoint - 1px;
$x-large-breakpoint       :  1800px;
$x-large-breakpoint-below :  $x-large-breakpoint - 1px;



// Other
//----------------------------------

$ls-login-bg-color-overlay:   rgba(38 ,50 ,56,.6);
$ls-login-3-bg-color-overlay: rgba(0, 0 , 0, .35);

$headings-margin-bottom:        1.2rem;
$card-cap-bg:                   #fff;

$pace-loader-color :            darken($ls-color-primary, 10%);
