.table {

    th,
    td {
        color: $ls-color-black;
        font-weight: 300;
        border-top: none;
        font-size: 15px;
        vertical-align: middle;
    }

    td {
        border-right: 0;
        border-left: 0;
        // cursor: pointer;
    }

    th {
        text-transform: uppercase;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
        position: relative;
        color: $ls-color-black--light;
        letter-spacing: 0.3px;
    }

    .table__actions {
        display: flex;
        // opacity: 0;

        .btn {
            padding: 2px 0.5rem;
        }

        a:not(:last-child) {
            border-right: 1px solid #d9d9d9;
            margin-right: 8px;
            padding-right: 8px;
        }

    }

    thead th {
        border: 0;
        position: relative;
        top: 25px;
    }

    .table-component__table__body {
        position: relative;
        tr:first-child td {
            border-top: 0;
        }

        tr {
            border-radius: 10px;
            transition: all ease-in-out 0.2s;
        }

        tr:hover {
            box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.03);
        }

        td {
            font-size: 15px;
            padding: 25px 15px;
            height: 80px;
            background: #ffffff;

            &:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            &:last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }
    }

    td.primary {
        font-weight: 400;
        font-size: 16px;
    }

    .action-dropdown {

        .dropdown-container {
            margin: 10px 5px 0 0;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
            border: none;
        }

        .dropdown-item {
            justify-content:space-between;
            color: #040405;
            padding: 10px 20px;
        }
    }
}

.card-body .table {
    margin-top: -10px;

    th {
        border-top: none;
    }
}

// Vue Table

.table-component {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: -25px;
    min-height: 300px;
}

.table-component__filter {
    align-self: flex-end;
    position: relative;
}

.table-component__filter__field {
    padding: 0.15em 1.25em 0.15em 0.75em;
    border: solid 1px #e0e0e0;
    font-size: 15px;
    border-radius: 3px;
}

.table-component__filter__clear {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    color: #007593;
    font-weight: bold;
    cursor: pointer;
}

.table-component__filter__field:focus {
    outline: 0;
    border-color: $ls-color-primary;
}

.table-component__table-wrapper {
    width: 100%;
}

.table-component__table {
    min-width: 100%;
    border-collapse: separate;
    table-layout: auto;
    margin-bottom: 0;
    border-spacing: 0 15px;
}

.table-component__table__caption {
    position: absolute;
    top: auto;
    left: -10000px;
    overflow: hidden;
    width: 1px;
    height: 1px;
}

.table-component__table th,
.table-component__table td {
    padding: 0.75em 1.25em;
    vertical-align: top;
    text-align: left;
}

.table-component__message {
    color: #999;
    text-align: center;
    padding-top: 10px;
}

.table-component__th--sort,
.table-component__th--sort-asc,
.table-component__th--sort-desc {
    // text-decoration: underline;
    cursor: pointer;
    user-select: none;

}

.table-component__th--sort-asc:after,
.table-component__th--sort-desc:after {
    position: absolute;
    left: 0.25em;
    display: inline-block;
    color: #bbb;
}

.table-component__th--sort-asc:after {
    content: "â";
}

.table-component__th--sort-desc:after {
    content: "â";
}


// Pagination
.table-component .pagination {
    justify-content: flex-end !important;
    margin-top: 10px;

    .page-item {
        overflow: hidden;
    }

    .page-item.active .page-link {
        color: #fff !important;
    }

    a i {
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        background-color: #fff;
        border: 1px solid #dee2e6;
        font-style: normal;
        cursor: pointer;
    }

    a.disabled i {
        color: #d9d9d9;
        pointer-events: none;
        cursor: auto;
    }

}

table.full-width {
    width: 100%;
}

.table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    border-bottom: 2px solid $ls-color-gray--light;
    height: 40px;
    position: relative;

    .table-actions-button {
        display: block;
        font-weight: 500;
        cursor: pointer;
        color: $ls-color-primary--light;
        user-select: none;

        &::after {
            vertical-align: 0.1em;
        }
    }

    .dropdown-group.active {

        .dropdown-container {
            z-index: 10;
        }

    }

    .table-stats {
        margin: 0;
    }

}

.selectall {
    position: absolute;
    cursor: pointer;
    top: 47px;
    left: 35px;
    z-index: 10;
}

.table-component td > span:first-child {
    background: $ls-color-gray--light;
    color: $ls-color-secondary;
    display: none;
    font-size: 10px;
    font-weight: bold;
    padding: 5px;
    left: 0;
    position: absolute;
    text-transform: uppercase;
    top: 0;
}

.select-all-label {
    display: none !important;
}

@media(max-width: $small-breakpoint) {
    .select-all-label {
        display: inline !important;
        color: $ls-color-secondary;
        cursor: pointer;
    }

    .selectall {
        top: 20px;
    }

    .table-component {

        .dropdown-group {
            position: absolute;
            visibility: visible;
            top: 15px;
            right: 10px;
        }

        thead {
            left: -9999px;
            position: absolute;
            visibility: hidden;
        }

        tr {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 50px;
            position: relative;
        }

        td {
            margin: 0 -1px -1px 0;
            padding-top: 40px !important;
            position: relative;
            width: 50%;
            left: 0;
            border: 1px solid $ls-color-gray--light !important;

            &:not(:first-child) {
                text-align: center !important;
            }

            &:first-child {
                display: flex;
                justify-content: space-between;
                flex: 1 100%;
                height: 50px;
                padding-top: 25px !important;
                align-items: center;
                border-bottom-left-radius: 0px !important;
                border-top-left-radius: 5px !important;
                border-top-right-radius: 5px !important;
            }

            &:last-child {
                position: unset;
                visibility: hidden;
                height: 0px !important;
                padding: 0px !important;
            }

            &:nth-last-child(3) {
                border-bottom-left-radius: 5px !important;
            }
            &:nth-last-child(2) {
                border-bottom-right-radius: 5px !important;
            }
        }

         td > span:first-child  {
            display: block;
        }

        .dropdown-container {
            right: 0;
            left: 120px;
        }
    }
}
