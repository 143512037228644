// Invoices - Create
// -------------------------

.invoice-create-page {
    position: relative;

    .page-header {
        margin: 0 0 40px 0;

        .page-title {
            font-size: 24.5px;
            margin: 0 0 15px 0;
        }

        .breadcrumb {

            .breadcrumb-item {
                font-size: 14px;
            }
        }

        .page-actions {

            .btn-crater {
                margin: 0 0 0 14px;
            }
        }

    }

    .invoice-input {
        padding-left: 40px;
    }

    .show-customer {
        background: #FFFFFF;
        padding: 0 10px 0 10px;
        min-height: 170px;
        border: 1px solid $ls-color-gray--light;

        label {
            font-size: 14px;
            margin: 0 0 0 0;
            padding: 0 0 0 0;
        }

    }

    .address-menu {
        display: flex;
        flex-direction: column;

        .title {
            font-size: 15px;
            white-space: nowrap;
            color: $ls-color-gray;
            font-weight: 500;
        }

        .content {
            display: flex;
            flex: 1;
            flex-direction: column;

            label {
                position: relative;
                white-space: nowrap;
                width: 90%;
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .customer-content {
        display: flex;
        justify-content: space-between;

        .email {
            flex: 8;
        }

        .action {
            cursor: pointer;
            flex: 1;
            font-size: 14px;
            font-weight: 500;
            color: $ls-color-primary;
            margin: 0 9px 0 0;
            position: relative;
        }
    }

    .add-customer {
        padding: 0 0 0 0;

        .add-customer-action {
            position: relative;
            display: flex;
            justify-content: center;
            background: #ffffff;
            border: 1px solid $ls-color-gray--light;
            min-height: 170px;
            border-radius: 5px;
            padding: 64px 0;

            .customer-icon {
                display: flex;
                justify-content: center;
                background: $ls-color-gray--light;
                width: 40px;
                color: white;
                height: 40px;
                font-size: 14px;
                font-family: Poppins;
                padding: 11px 14px 11px 13px;
                border-radius: 50%;
                margin: 0 15px 0 0;
            }

            label {
                font-size: 17.5px;
                margin: 7px 0 0 0;
            }
        }
    }

    .customer-required {
        border: 1px solid $ls-color-red;
        border-radius: 5px;
    }

    .offset-md-1 {
        margin-left: 5%;
    }

    .invoice-input-group {
        margin-bottom: 41px;
        margin-right: 0;

        .invoice-input {
            padding-right: 0;

            label {
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: $ls-color-black--light;
                margin: 0 0 10px 0;
            }
        }
    }

    .item-table {
        width: 100%;
        text-align: center;

        .item-table-header {
            border: 1px solid $ls-color-gray--light;
            background: white;

            th {
                padding: 10px 20px;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 21px;
                color: #55547A;
                border-top: 1px solid $ls-color-gray--light;
                border-bottom: 1px solid $ls-color-gray--light;
            }


            .item-heading {
                padding-left: 50px;
            }

            .amount-heading {
                padding-right: 50px;
            }

            th:first-child {
                padding-left: 0 !important;
                border-left: 1px solid $ls-color-gray--light;
            }

            th:last-child {
                padding-right: 0 !important;
                border-right: 1px solid $ls-color-gray--light;
            }
        }

        .item-body {

            td:first-child {
                padding-left: 0 !important;
            }

            td:last-child {
                padding-right: 0 !important;
            }

            .item-row {
                background: #FFFFFF;
                border: 1px solid $ls-color-gray--light;
                box-sizing: border-box;
                border-radius: 0px 0px 5px 5px;

                td {
                    padding: 15px 20px;
                    text-align: left;
                    vertical-align: top;
                }

                > td {
                    padding: 0;
                }

                .item-select {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    color: $ls-color-gray--dark;
                    overflow: auto !important;
                    max-height: 250px !important;
                }

                .search-select .selector-menu {
                    top: 45px !important;
                }

                .main {
                    padding: 0 !important;
                }

                .item-amount {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }

                .item-select-wrapper {
                    display: flex;
                    justify-content: flex-start;
                }

                .item-selector {
                    flex: 1;
                }

                .selected-item {
                    display: flex;
                    align-items: center;
                    height: 40px;
                    padding-left: 10px;
                    border: 1px solid $ls-color-gray--light;
                    background: $ls-color-gray--very-light;
                    border-radius: 5px;
                    position: relative;
                }

                .deselect-icon {
                    color: $ls-color-gray;
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    cursor: pointer;
                }

                .sort-icon-wrapper,
                .remove-icon-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 15px;
                    height: 40px;
                    width: 20px;
                    cursor: pointer;
                }

                .sort-icon-wrapper {
                    color: $ls-color-gray;
                    height: 20px;
                    margin-top: 8px;
                    cursor: move;
                }

                .remove-icon {
                    color: $ls-color-secondary;
                }

            }
        }
    }

    .tax-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .tax-amount {
            flex: 3;
        }

        .tax-select {
            flex: 4;
        }
    }

    .tax-tr td {
        padding: 0 21px;
    }

    .multi-select-item {
        .multiselect,
        .multiselect__input,
        .multiselect__single {
            border: none;
            display: flex;
            align-items: center;
        }


        .multiselect:focus {
            outline: none;
        }

        .multiselect__input,
        .multiselect__single {
            min-height: 40px;
            padding-left: 10px;
            border: 1px solid rgb(234, 241, 251);
        }

         .multiselect__tags {
            // margin-bottom: 8px;
            min-height: 30px;
            padding:  0;
            border: 0px ;
        }
          .multiselect__select {
            line-height: 16px;
            width: 40px;
            height: 38px;
            transition: transform 0.2s ease;
        }

        .multiselect__content-wrapper {
            overflow: hidden;
            border: 1px solid $ls-color-gray--light;
            box-shadow: $shadow-md;
            border-radius: 0px 0px 5px 5px;
        }

        .multiselect__content {
          max-height: 240px;
          overflow: auto;
        }
    }

    .add-item-action {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $ls-color-primary--light;
        padding: 13px 23px;
        width: 100%;
        font-size: 15px;
        border-bottom: 1px solid $ls-color-gray--light;
        cursor: pointer;

        &:hover {
            background: $ls-color-gray--light;
        }
        .icon-fa {
            margin: 2px 11px 0 0;
        }
    }

    .invoice-foot {
        display: flex;
        justify-content: space-between;
        margin: 35px 0;

        .btn-template {
            background: $ls-color-gray--light;
            border: 1px solid $ls-color-gray--light;
            color: $ls-color-black;
            font-size: 14px;
        }

        .icon-button {
            color: $ls-color-gray--dark;
        }

        label {
            font-size: 14px;
            margin: 0 0 0 0;
        }

        .invoice-notes {
            display: flex;
            flex-direction: column;
            margin: 5px 0 0 0;

            textarea {
                border: 1px solid $ls-color-gray--light;
                border-radius: 5px;
                width: 100%;
                min-height: 100px;
                padding: 0 0 0 0;
            }
        }

        .invoice-total {
            display: flex;
            flex-direction: column;
            background: #ffffff;
            min-width: 390px;
            padding: 15px 20px;
            border: 1px solid $ls-color-gray--light;
            border-radius: 5px;

            &> label {
                text-transform: uppercase;
            }

            .section {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0 0 7px 0;

                .invoice-label {
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: uppercase;
                    line-height: 21px;
                    color:$ls-color-gray--dark;
                }

                .invoice-amount {
                    font-size: 18px;
                    color: #55547A;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .total {
                    color: $ls-color-primary--light;
                }

                .discount-drop-down {
                    width: 105px;
                }

                .item-dropdown {
                    font-family: Arial, Helvetica, sans-serif !important;
                    font-size: 16px;
                }
            }

            .border-top {
                margin: 0;
                padding-top: 7px;
                border-top: 1px solid $ls-color-gray--light;
            }

            .tax-selector {
                font-weight: 600;
                font-size: 14px;
                margin-top: 12px;
                line-height: 21px;
                color: $ls-color-primary--light;
            }
        }
    }

    .item-row {

        .item-name {
            font-weight: 500;
            text-align: left;
            font-size: 14px;
        }

        .item-description {
            width: 100%;
            font-weight: 300;
            font-size: 12px;
            padding-top: 2px;
            // padding-left: 10px;

            textarea {

                &::placeholder {
                    font-family: Poppins;
                    font-style: normal;
                    font-size: 12px;
                    color: $ls-color-gray;
                }

                border: none;
                resize: none;
                width: 100%;
                padding-left: 11px;
                color: $ls-color-gray--very-dark;
            }
        }

        .action {
            font-weight: 500;
            font-size: 14px;
            color: $ls-color-primary;
            cursor: pointer;
        }

        .activator {
            label {
                color: $ls-color-gray--dark;
                font-size: 14px;
            }
        }
    }

    .item-dropdown {
        display: flex;
        border: 1px solid $ls-color-gray--light !important;
        background-color: #fff !important;
        border-radius: 0px 5px 5px 0px !important;
        justify-content: center;
        align-items: center;
        height: 40px;

        &:focus {
            box-shadow: none !important;
        }
    }

    .item-discount {
        border-radius: 5px 0px 0px 5px !important;
        height: 40px;
        // border-right: 0px !important;
    }

    @media (max-width: $x-small-breakpoint) {

        .page-header {
            margin-bottom: 13px;

            .invoice-action-btn {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 15px;
                margin-right: 0px !important;
            }

        }

        .invoice-customer-container {
            padding-right: 0px;
        }

        .collapse-input {
            padding-right: 0px;
        }

        .page-actions {
            position: relative;
            -webkit-transform: none;
            -ms-transform: none;
            -o-transform: none;
            transform: none;
            display: flex;
            flex-wrap: wrap;

            button {
                margin-bottom: 10px;
            }
        }

        .invoice-input-group {

            .invoice-input {
                padding-right: 15px;
                padding-left: 20px;
                margin-top: 1.5rem;
            }

        }

        .invoice-foot {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .btn-template {
                width: 100%;
                display: flex;
                justify-content: center;
            }

            .invoice-total {
                min-width: 384px;
                margin-top: 25px;
            }

        }

    }

}
