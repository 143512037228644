
.customer-select {

    label {
        cursor: pointer;
    }

    .main {
        display: flex;
        flex-direction: column;
        width: 100%;

        .search-bar {
            display: flex;
            padding: 15px 15px 5px 15px;

            .search-icon {
                color: $ls-color-gray;
                font-size: 14px;
                font-weight: 900;
                margin: 4px 0 0 0;
            }
        }

        .no-data-label {
            display: flex;
            justify-content: center;
            color: $ls-color-gray;
            padding: 20px 20px;

            label {
                margin: 0 0 0 0;
            }
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;
        max-height: 173px;
        min-height: 173px;

        .list-item {
            display: flex;
            padding: 10px 23px;
            border-bottom: 1px solid rgba(185, 193, 209, 0.41);
            cursor: pointer;
        }

        .avatar {
            width: 35px;
            height: 35px;
            background:$ls-color-gray;
            border-radius: 50%;
            margin: 1px 21px 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $white;
        }

        .name {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .title {
                font-size: 16px;
                font-family: Poppins;
                margin: 0 0 0 0;
                line-height: 1.2;
            }

            .sub-title {
                font-family: Poppins;
                font-weight: 500;
                font-size: 12px;
                color: $ls-color-gray--dark;
                letter-spacing: 0.5px;
                margin: 0 0 0 0;
            }
        }

        .list-item:last-child {
            border: none;
        }

        .list-item:hover {
            background: $ls-color-gray--very-light;
            cursor: pointer;
        }

    }

}
