// Settings
// -------------------------

.settings-container {
    margin-top: 40px;

    .settings-sidebar {
        list-style-type: none;
        padding: 0px;

        .settings-menu-item {
            padding: 10px 0px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: $ls-color-black;

            .setting-icon {
                width: 25px;
                color: $ls-color-gray;
                font-size: 16px;
            }

        }

        .link-color {
            color: $ls-color-gray--dark;
            display: flex;
            align-items: center;
        }

        .active-setting {
            color: $ls-color-primary !important;
            cursor: pointer;

            .setting-icon {
                color: $ls-color-primary !important;
            }
        }
    }

    .setting-main-container {

        .setting-card {
            padding: 30px;
            min-height: 620px;
        }

        .page-header {
            margin: 0px;

            .page-title {
                margin: 0;
                padding-bottom: 10px;
                font-weight: 500;
                font-size: 17.5px;
                line-height: 21px;
                letter-spacing: 0.1px;
                color: #000000;
            }

            .page-sub-title {
                max-width: 480px;
                font-size: 14px;
                line-height: 21px;
                color: $ls-color-gray--dark;
                margin-bottom: 30px;
            }

        }

        .input-label {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #55547A;
        }

    }

    .expense-category {
        td {
            background-color: $ls-color-gray--very-light !important;
        }
    }

    .tax-table {
        td {
            background-color: $ls-color-gray--very-light !important;
        }

        .tax-name {

        }

        .compound-tax {
            display: inline;
            padding: 2px 8px;
            font-size: 10px;
            line-height: 15px;
            background: $red--very-light;
            color: $red--dark;
        }
    }

    .payment-tab {

        .dropdown-container {
            right: 0;
            left: auto;
        }

    }

    .item-tab {

        .dropdown-container {
            right: 0;
            left: auto;
        }

    }

    .add-new-tax {
        height: 45px;
        white-space: nowrap;
        z-index: 1;
    }

    .flex-box {
        display: flex;
    }

    .left {
        position: relative;
        width: 50px;
    }

    .btn-switch {
        position: absolute;
        top: -18px;
    }

    .box-title {
        margin-bottom: 5px;
        padding: 0;
        font-size: 16px;
        line-height: 21px;
        color: #55547A;
    }

    .box-desc {
        margin: 0;
        padding: 0;
        max-width: 480px;
        font-size: 12px;
        line-height: 18px;
        color: $ls-color-gray--dark;
    }

    .notes {
        overflow: hidden;
        width: 200px;

        .note {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .version {
        background: #EAF1FB;
        color: #A5ACC1;
        border: 1px solid #EAF1FB;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 15px;
    }
}

@media(max-width: $x-small-breakpoint ) {

    .settings-container {

        .setting-main-container {

            .setting-card {
                overflow: scroll;
            }

            & .ml-15 {
                margin-left: 15px;
            }

            .page-header {
                flex-wrap: wrap;
            }

        }

        .table-component {

            td:first-child {
                height: auto;
                min-height: 10px;
                text-align: center;
                justify-content: center;
            }

        }

        .tax-table {

            .tax-name {
                margin-top: 21px;
                width: 76%;
            }

        }

        .expense-category {

            .table-component__table__body {

                tr:first-child {

                    td:nth-child(2) {
                        height: auto;
                        width: 101%;
                    }

                }

            }

        }

        .add-new-tax {
            position: relative;
            z-index: 1;
        }

    }

}
