// Dashboard
// -------------------------

.dashboard {

    .dashboard-table
    .table-component {

        .dashboard-recent-invoice-options {

            .dropdown-container {
                margin: 10px 5px 0 -75px;
            }

        }

    }

}

.dashbox {
    background-color: $white;
    box-shadow: $shadow;
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;

    i {
        font-size: 30px;
        left: 40px;
        position: absolute;
        top: 53px;
    }

    .title {
        color: $ls-color-gray--dark;
        display: block;
        font-size: 17.5px;
        line-height: 21px;
        margin-top: 5px;
    }

    .amount {
        color: $ls-color-black;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
    }

    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
        background-color: $ls-color-gray--very-light;
    }
}

.dash-item {
    flex: 3;
    padding: 0 15px 0 15px;
}
.dash-item:first-child {
    flex: 5
}

.dashboard-card .card-header {
    border: none !important;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    padding-left: 10px;

    h6 {
        font-weight: 500;
        font-size: 17.5px;
        line-height: 21px;
        letter-spacing: 0.1px;
        flex: 1;
    }

    .year-selector {
        height: 50px;
        width: 150px;
    }
}

.graph-body {
    display: flex !important;

    .chart-desc {
        border-left: 1px solid $ls-color-gray--light;
        padding: 0
    }

    .stats {
        text-align: right;
        display: flex;
        flex-direction: column;
        height: 100%;

        .description {
            padding-top: 30px;
            flex: 1;
        }

        .description:last-child {
            border-top: 1px solid $ls-color-gray--light;
            padding-top: 30px;
        }

        .title {
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.01em;
            padding-right: 25px
        }

        .total {
            display: block;
            margin-top: 6px;
            font-weight: 600;
            font-size: 24.5px;
            line-height: 29px;
            padding-right: 25px
        }
    }
}

.table-row {
    margin-top: 20px;

    .table-header {
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: center;
        z-index: 1;
        margin-bottom: 10px;

        .table-title {
            font-weight: 600;
            font-size: 21px;
            line-height: 25px;
            margin-bottom: 0;
        }
    }
}

// Ecommerce

.dashbox-line-progress {
    padding: 30px;
    text-align: left;

    i {
        right: 30px;
        left: auto;
        top: 30px;
    }

    .progress {
        height: 0.5rem;
        margin-bottom: 0;
        background-color: $ls-color-gray--light;
    }

    .title {
        margin-bottom: 15px;
        font-size: 26px;
    }
}

.dashbox-circle-progress {
    text-align: left;
    padding: 30px;

    .easy-pie-chart {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translate(0, -50%);
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .dashbox {
        padding: 15px;

        i {
            left: 30px;
            top: 25px;
        }

        &.dashbox-line-progress i {
            right: 15px;
            left: auto;
            top: 15px;
        }
    }

    .dashbox-circle-progress {
        padding: 30px;
    }
}

@media (max-width: 1200px) {
    .dashbox {
        padding: 12px;

        .title {
            font-size: 14px;
            line-height: 16px;
            margin-top: 3px;
        }

        .amount {
            font-weight: 500;
            font-size: 24px;
            line-height: 30px;
        }
    }

    .graph-body {
        display: block !important;

        .stats {
            flex-direction: row;
            flex-wrap: wrap;
            text-align: center;
            border-top: 1px solid $ls-color-gray--light;

            .total {
                padding-right: 0;
            }

            .description {
                padding:30px 25px;
            }
            .description:last-child {
                border-top: none;
                padding-top: 30px;
                border-left: 1px solid $ls-color-gray--light;
            }
        }

    }
}

@media (max-width: 992px) {

    .dashbox
    .icon
    .card-icon {
        height: 40px;
        width: 40px;
    }

    .dashboard-card
    .card-header {
        margin-bottom: 20px;

        .year-selector {
            height: 40px;
        }
    }

    .dashboard-card
    .card-header {
        padding-left: 0;

        h6 {
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0;
        }
    }

    .dash-item {
        flex: 3px;
        padding: 0 15px 0 15px;
    }

    .dash-item:nth-child(odd) {
        padding: 0 15px 0 15px;
        flex: 100%
    }
    .dash-item:nth-child(even) {
        padding: 0 15px 0 15px;
        flex: 100%
    }

    .graph-body {

        .stats {

            .title {
                font-size: 12px;
                line-height: 12px;
                padding-right: 0;
            }

            .total {
                display: block;
                margin-top: 3px;
                font-weight: 600;
                font-size: 16px;
                line-height: 1.2;
            }

            .description {
                padding: 10px;
            }
            .description:last-child {
                padding: 10px;
                flex: 100%;
                border-top: 1px solid #EBF1FA;

                .title {
                    padding-right: 0;
                }
            }
        }
    }

    .table-row
    .mob-table {
        margin-top: 25px !important;
    }
}

//Finanace
.card.with-tabs .tabs-component {

    .tabs-component-tabs {
        position: absolute;
        right: 1.25rem;
        top: 14px;
    }

    .tabs-component-panels {
        border: 0 !important;
    }
}
@media(max-width: $small-breakpoint) {
    .card.with-tabs .tabs-component {

        .tabs-component-tabs {
            position: relative;
            display: block;
            margin-right: -41px;
            border-radius: 0;
            top: -21px;
            margin-left: -1px;
        }

        .tabs-component-panels {
            // border: 0 !important;
        }
        .tabs-component-tab:not(:last-child) {
            border-bottom: dotted 1px $ls-color-gray;
        }
    }

    .dashboard-table
    .table-component {

        tr {
            &:first-child {
                margin-top: 20px;
            }
        }

        td {
            &:first-child {
                padding-top: 40px !important;
                justify-content: center;
            }
        }

    }

}
